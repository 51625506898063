.login-background {
    min-height: 100vh;
    background: #2D3B55;
}

.main-container {
    width: 400px;
    padding: 8% 0 0;
    margin: auto;
}

.login-form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 400px;
    margin: 0 auto 100px;
    padding: 30px;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.website-name {
    color: #2D3B55;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 700;
}

.login-welcome-msg .welcome {
    margin: 0;
}

.login-welcome-msg .msg {
    margin: 4px 0 30px 0;
}

.login-form .content {
    text-align: center;
}

.login-form .input-field input {
    outline: 0;
    width: 100%;
    margin: 0 0 15px;
    padding: 12px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #7e7e7e;
    background-color: #F5F3F6;
}

.login-form .input-field input:focus {
    border-color: #222;
}

.login-form a.link {
    text-decoration: none;
    color: #747474;
    letter-spacing: 0.2px;
    display: inline-block;
    margin: 20px 0;
}

.login-form a.link:hover{
    color: #2D3B55;
}

.login-form .action {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.login-form .action button {
     text-transform: uppercase;
    outline: 0;
    background: #ffb200;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 8px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-form .action button:hover {
    background: #eba400;
    transform: translateY(-1px);
}

@media screen and (max-width: 420px) {
    .main-container {
        width: 300px;
    }

    .login-form {
        max-width: 300px;
    }
}
