@import url("https://fonts.googleapis.com/css?family=Public+Sans:300,400,500,600,700,800");

html {
    height: 100%;
    min-height: 100%;
    /* Uncomment if we want for text to be unselectable */
    /*-webkit-user-select: none; /* Safari */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /* IE10+/Edge */
    /*user-select: none;*/
}

body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    background: #f4f8f9;
    font-family: "Public Sans";
}